<template>
  <label class="SwitchUI" :class="{
    'SwitchUI--checked': value,
    'SwitchUI--disabled': disabled,
  }">
    <input
      class="SwitchUI__input"
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @change="$emit('input', !value)"
    />

    <div class="SwitchUI__area">
      <div class="SwitchUI__areaCircle"></div>
    </div>

    <span v-if="label" class="SwitchUI__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'SwitchUI',

  props: {
    label: String,
    disabled: Boolean,
    inline: Boolean,

    value: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-theme {
  .SwitchUI {
    &:hover {
      .SwitchUI__area {
        border-color: $darkThemeBlue;
      }

      .SwitchUI__areaCircle {
        background-color: $darkThemeBlue;
      }
    }

    &__area {
      border-color: $darkThemeText;
      background-color: $darkThemeBgOne;
    }

    &__areaCircle {
      background-color: $darkThemeText;
    }

    &__label {
      color: $darkThemeTextLight;
    }

    &--checked, &--checked:hover {
      .SwitchUI__area {
        border-color: $darkThemeBlue;
        background-color: $darkThemeBlue;
      }

      .SwitchUI__areaCircle {
        background-color: $darkThemeBgOne;
      }
    }

    &--disabled {
      .SwitchUI__label {
        color: $darkThemeText;
      }
    }
  }
}

.SwitchUI {
  $root: &;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0 12px;
  align-items: center;
  width: max-content;

  &:hover {
    cursor: pointer;

    #{$root}__area {
      border-color: $blue;
    }

    #{$root}__areaCircle {
      background-color: $blue;
    }
  }

  // Поле ввода
  &__input {
    display: none;
  }

  // Зона
  &__area {
    display: grid;
    align-items: center;
    width: 32px;
    height: 16px;
    border: 1px solid $borderColor;
    border-radius: 8px;
    background-color: #FFFFFF;
    transition: all 0.2s;
  }

  &__areaCircle {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 8px;
    background-color: $borderColor;
    transition: all 0.2s;
  }


  // Лейбл
  &__label {
    color: #4D525E;
    font-size: 14px;
    font-weight: 300;
  }

  // Модификаторы
  &--checked, &--checked:hover {
    #{$root}__area {
      border-color: $blue;
      background-color: $blue;
    }

    #{$root}__areaCircle {
      transform: translateX(15px);
      background-color: #FFFFFF;
    }
  }

  &--disabled {
    pointer-events: none;

    #{$root}__label {
      color: $darkGrey;
    }
  }
}
</style>
